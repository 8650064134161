import React from "react"
import Layout from "../components/organisms/layout"
import SEO from "../components/seo"
import Wrapper from "../components/atoms/page-wrapper"
import { graphql } from "gatsby"
import Heading from "../components/atoms/newsletter-heading"
import Img from "gatsby-image"
import LinkStyled from "../components/atoms/action-paragraph-link"

const CertificatesWrapper = ({ data }) => (
  <Layout
    cert1={data.cert1.childImageSharp.fluid}
    cert2={data.cert2.childImageSharp.fluid}
  >
    <SEO title="Certyfikaty" />
    <Wrapper noImg>
      <Heading>Certyfikat Cambridge English</Heading>
      <p>
        Placówka posiada status Centrum Przygotowującego do egzaminów Cambridge
        English Qualifications i jest partnerem Autoryzowanego Centrum
        Egzaminacyjnego Cambridge English PL277.
      </p>
      <p>
        Listę dostępnych certyfikatów i testów można sprawdzić{" "}
        <LinkStyled to={"/cambridge-assessment"}>na tej stronie</LinkStyled>.
      </p>
      <Img
        fluid={data.assessmnet.childImageSharp.fluid}
        alt={"Cambridge English PL277"}
      />
    </Wrapper>
  </Layout>
)

export const query = graphql`
  query {
    assessmnet: file(relativePath: { eq: "cambridge_assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert1: file(relativePath: { eq: "cambridge-english-assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert2: file(relativePath: { eq: "direct-method.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 244, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default CertificatesWrapper
